<template>
  <div class="login-warp">
    <div class="logon-form">请从【商砼ERP】点击【外加剂调配】重新登录。</div>
    <!-- <div class="logon-form">
      <a> <span>点此处可测试登录, 发布后不展示此按钮 </span> </a>
      <div>
        <a-form
          ref="form"
          :model="state"
          autocomplete="off"
          :labelCol="{ span: 6 }"
        >
          <a-form-item label="compid" name="compid" required>
            <a-input v-model:value="state.compid" />
          </a-form-item>
          <a-form-item label="compname" name="compname" required>
            <a-input v-model:value="state.compname" />
          </a-form-item>
          <a-form-item label="empname" name="empname" required>
            <a-input v-model:value="state.empname" />
          </a-form-item>
          <a-form-item label="opId" name="opId" required>
            <a-input v-model:value="state.opId" />
          </a-form-item>
          <a-button type="primary" @click="login" block>登录</a-button>
        </a-form>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import Qs from "qs";
import { useRouter } from "vue-router";
const router = useRouter();
const defaultState = () => ({
  compid: "01",
  compname: "砼鑫商砼",
  empname: "JACK",
  opId: "666",
});
const state = reactive(defaultState());
const form = ref(null);

const login = async () => {
  await form.value.validateFields();
  console.log(`/transform?${Qs.stringify(state)}`);
  router.push({ path: "/transform", query: { ...state } });
};
</script>

<style lang="less" scoped>
.login-warp {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: url("http://auto.hntxrj.com/bg.png");
  .logon-form {
    // width: 400/80rem;
    background: #00000033;
    padding: 40/80rem;
    border-radius: 10/80rem;
  }
}
</style>
